import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import { v4 as uuidv4 } from "uuid"
import parse from "html-react-parser"

import Layout from "../components/Layout"
import Seo from "../components/Seo"
import Banner from "../components/Banner"
import PartnerLogo from "../components/PartnerLogo"
import ButtonLink from "../components/ButtonLink"
import Card from "../components/Card"

const RegiePubPage = function ({
  data: { regiePubJson, allRegiePubItemsJson },
  location,
}) {
  return (
    <Layout>
      <Seo
        title={regiePubJson.title}
        description={regiePubJson.description}
        pathname={location.pathname}
      />
      <Banner
        background={regiePubJson.banner.background}
        content={regiePubJson.banner.content}
      />
      <div className="container">
        <section>
          {parse(regiePubJson.content)}

          <div>
            <ul>
              {regiePubJson.partners.block1.map(function (item) {
                return <PartnerLogo key={uuidv4()} item={item} />
              })}
            </ul>

            <ul>
              {regiePubJson.partners.block2.map(function (item) {
                return <PartnerLogo key={uuidv4()} item={item} />
              })}
            </ul>

            <ul>
              {regiePubJson.partners.block3.map(function (item) {
                return <PartnerLogo key={uuidv4()} item={item} />
              })}
            </ul>

            <ul>
              {regiePubJson.partners.block4.map(function (item) {
                return <PartnerLogo key={uuidv4()} item={item} />
              })}
            </ul>

            <ul>
              {regiePubJson.partners.block5.map(function (item) {
                return <PartnerLogo key={uuidv4()} item={item} />
              })}
            </ul>
          </div>

          {parse(regiePubJson.content2)}

          {parse(regiePubJson.content3)}

          {parse(regiePubJson.content4)}

          {parse(regiePubJson.content5)}
          <ul className="grid grid-cols-2 md:grid-cols-4 gap-8 mb-5">
            {allRegiePubItemsJson.nodes.map(function (item) {
              return <Card key={uuidv4()} item={item} />
            })}
          </ul>
          <div className="text-center mb-6">
            <ButtonLink item={regiePubJson.content5Button} />
          </div>

          {parse(regiePubJson.stepsCampaign.content)}
          <ul>
            {regiePubJson.stepsCampaign.list.map(function (item) {
              return (
                <li key={uuidv4()}>
                  <GatsbyImage
                    image={item.icon.childImageSharp.gatsbyImageData}
                    alt="Icone"
                  />
                  {item.label}
                </li>
              )
            })}
          </ul>

          {parse(regiePubJson.whyCommunicate.content)}
          <ul>
            {regiePubJson.whyCommunicate.list.map(function (item) {
              return (
                <li key={uuidv4()}>
                  <GatsbyImage
                    image={item.icon.childImageSharp.gatsbyImageData}
                    alt="Icone"
                  />
                  {item.label}
                </li>
              )
            })}
          </ul>
        </section>
      </div>
    </Layout>
  )
}

RegiePubPage.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
}

export default RegiePubPage

export const query = graphql`
  query RegiePubPagePageQuery {
    regiePubJson {
      title
      description
      banner {
        background {
          publicURL
        }
        content
      }
      content
      partners {
        block1 {
          name
          logo {
            childImageSharp {
              gatsbyImageData
            }
          }
          link
        }
        block2 {
          name
          logo {
            childImageSharp {
              gatsbyImageData
            }
          }
          link
        }
        block3 {
          name
          logo {
            childImageSharp {
              gatsbyImageData
            }
          }
          link
        }
        block4 {
          name
          logo {
            childImageSharp {
              gatsbyImageData
            }
          }
          link
        }
        block5 {
          name
          logo {
            childImageSharp {
              gatsbyImageData
            }
          }
          link
        }
      }
      content2
      content3
      content4
      content5
      content5Button {
        label
        link
      }
      stepsCampaign {
        content
        list {
          icon {
            childImageSharp {
              gatsbyImageData
            }
          }
          label
        }
      }
      whyCommunicate {
        content
        list {
          icon {
            childImageSharp {
              gatsbyImageData
            }
          }
          label
        }
      }
    }
    allRegiePubItemsJson {
      nodes {
        title
        subTitle
        link
        banner {
          background {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
  }
`
