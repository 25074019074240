import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import { GatsbyImage, StaticImage } from "gatsby-plugin-image"
import parse from "html-react-parser"

const Card = function ({ item }) {
  let image
  if (typeof item.banner !== `undefined` && item.banner !== null) {
    image = (
      <GatsbyImage
        image={item.banner.background.childImageSharp.gatsbyImageData}
        alt={item.title}
      />
    )
  } else if (
    typeof item.background !== `undefined` &&
    item.background !== null
  ) {
    image = (
      <GatsbyImage
        image={item.background.childImageSharp.gatsbyImageData}
        alt={item.title}
      />
    )
  } else if (typeof item.image !== `undefined` && item.image !== null) {
    image = (
      <GatsbyImage
        image={item.image.childImageSharp.gatsbyImageData}
        alt={item.title}
      />
    )
  } else if (typeof item.category !== `undefined` && item.category !== null) {
    if (item.category === `emploi`) {
      image = (
        <StaticImage
          src="../images/ensemble-au-micro/bg-minute-emploi.jpg"
          alt="Minute Emploi"
        />
      )
    }
  }
  return (
    <li className="relative">
      <h3>
        {item.link ? (
          <Link className="stretched-link" to={item.link}>
            {item.title}
          </Link>
        ) : (
          item.title
        )}
      </h3>
      {item.subTitle && <p>{item.subTitle}</p>}
      {image}
      {item.description && <p>{parse(item.description)}</p>}
    </li>
  )
}

Card.propTypes = {
  item: PropTypes.shape({
    category: PropTypes.string,
    title: PropTypes.string.isRequired,
    subTitle: PropTypes.string,
    description: PropTypes.string,
    link: PropTypes.string,
    background: PropTypes.string,
    image: PropTypes.string,
    banner: PropTypes.shape({
      background: PropTypes.string,
    }),
  }).isRequired,
}

export default Card
